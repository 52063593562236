/*global Component,__lodash__ */
class homeCategorySectionComponent extends Component {
    static name() {
        return "homeCategorySectionComponent";
    }

    static componentName() {
        return "homeCategorySectionComponent";
    }

    getProps() {
        return ['only_icons_view', 'open_by_default'];
    }

    data(){
        return {
            display: true,
            isMobile: $(window).width() <= 560,
            selectedCategory:'',
            menuCategories:[],
            submenucounter2:0,
            submenucounter3:0,
            itemsInSubMenu: 15,
        };
    }

    getWatch() {
        return {
            "showComponentCategory":function (newValue, oldValue){
                if (this.showComponentCategory) this.display = true;
            }
        };
    }

    getComputed(){
        return {
            session:function (){
                return this.$store.getters.getSession;
            },
            salesman:function (){
                return this.$store.getters.getSalesMan;
            },
            showComponentCategory:function (){
                return this.$store.getters.showCategoryTree;
            }
        };
    }

    mounted() {
        return async function () {
            let self = this;
            if (this.open_by_default != undefined) {
                this.display = this.open_by_default;
            } else {
                this.display = !this.isMobile;
                $(window).resize(function () {
                    self.isMobile = $(window).width() <= 560;
                    self.display = !self.isMobile;
                });
            }
            this.subscribeEvent('category-select', this.toggleMenu);
        };
    }

    getMethods(){
        return {
            tempCatHistory: this.tempCatHistory,
            getSubCategoriesProxyTool: this.getSubCategoriesProxyTool,
            getSubCategories: this.getSubCategories,
            catMouseOver: this.catMouseOver,
            catMouseClear: this.catMouseClear,
            isLeafCategory:this.isLeafCategory,
            processSubMenuLvl2: this.processSubMenuLvl2,
            processSubMenuLvl3: this.processSubMenuLvl3,
            mouseOver: this.mouseOver,
            getCategoryChilds: this.getCategoryChilds,
            toggleMenu: this.toggleMenu
        };
    }

    tempCatHistory(cat, lvl) {
        this.$store.dispatch('updateCategoryHistory', {temp: true, category: cat, lvl: lvl});
    }
    getSubCategoriesProxyTool(cat, categoryName) {
        let result = null;
        try {
            if (cat && this.getName(cat) == categoryName)
                result = cat.children;
            else {
                for (let chcat of cat.children) {
                    let deepSearch = this.getSubCategoriesProxyTool(chcat, categoryName);
                    if (deepSearch) {
                        return deepSearch;
                    }
                }
            }
            return result;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error in getSubCategoriesProxyTool');
        }
    }

    getSubCategories(categoryName) {
        let cat_list = this.$store.getters.getCategories;
        for (let cat of cat_list) {
            let deepSearch = this.getSubCategoriesProxyTool(cat, categoryName);
            if (deepSearch)
                return deepSearch;
        }
        return [];
    }

    catMouseOver(event) {
        let oldValue = this.selectedCategory;
        if (oldValue != event) {
            let subMenu = this.getSubCategories(event);
            this.menuCategories = subMenu;
        }
        this.selectedCategory = event;

    }

    catMouseClear(event) {
        //this.menuCategories =[];
    }

    isLeafCategory(cat) {
        try {
            if (cat.leaf || cat.children.length == 0)
                return true;
            return false;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('isLeadCategory Error');
        }
    }

    processSubMenuLvl2() {
        this.submenucounter2 = this.submenucounter2 + 1;
    }

    processSubMenuLvl3() {
        this.submenucounter3 = this.submenucounter3 - 1;
    }

    mouseOver(cat) {
        // eslint-disable-next-line no-console
        console.log('Change Selected Category', cat);
        this.selectedCategory = cat;
    }

    getCategoryChilds() {
        let globalCategory = this.$store.getters.getGlobalCategories;
        let code = (window.location.pathname.split('/'))[1];
        let list = [];
        if (this.$store.getters.getCategories.length > 0) {
            for (let cat of this.$store.getters.getHomeCategories) {
                let catInfo = this.$store.state.cat_by_code.get(cat.fields.CatCode);
                if (catInfo && (!catInfo.settingsCode || catInfo.settingsCode && catInfo.settingsCode.split(",").includes(code))) {
                    list.push(catInfo.code)
                }
            }
            return __lodash__.orderBy(this.$store.getters.getCategories[0]['children'].filter(x=>globalCategory.indexOf(x.code) === -1 && list.includes(x.code)), 'name', 'asc');
        }
        else
            return [];
    }

    toggleMenu() {
        if (this.isMobile) {
            this.display = !this.isMobile;
        }
    }

    getTemplate(){
        return `<div class="filter" v-if="showComponentCategory">
                <p class="filter-title" @click="display = !display">
                    {{tr("Categories")}}
                    <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                    <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                </p>
                <div class="filter-options category-list" :class="{'visible-filter': display}">
                    <template v-for="(cat, index)  in getCategoryChilds()">
                        <template v-if="cat.children.length > 0 && cat.hide==0">
                            <catComponent :cat="cat" :lvl="parseInt(0)"></catComponent>
                        </template>
                    </template>
                    <template v-for="(cat, index)  in getCategoryChilds()">
                        <template v-if="cat.children.length == 0 && cat.hide==0">
                            <catComponent :cat="cat" :lvl="parseInt(0)"></catComponent>
                        </template>
                    </template>
                 </div>
              </div>`;
    }

}

homeCategorySectionComponent.registerComponent();
